
import { fetchOnStockUsers, onStockStore } from '@/modules/onstock'
import { CsFlex, CsTooltipAvatar, CsWarningDialog, CsBtn, CsAvatar } from '@consteel/csuetify'
import Vue from 'vue'
import { createOnStockUser, deleteOnStockUser, OnStockPermission } from '@/modules/onstock'
import { authStore } from '@/modules/auth'
import AddCollaboratorDialog, { Collaborator } from './AddCollaboratorDialog.vue'
import { OnStockUser } from '@/modules/graphql/graphql.types'

export default Vue.extend({
  name: 'Collaborators',
  components: {
    CsAvatar,
    CsFlex,
    CsBtn,
    CsWarningDialog,
    CsTooltipAvatar,
    AddCollaboratorDialog,
  },
  data() {
    return {
      search: '',
      loading: false,
      deleteUserDialog: false,
      deleteProjectOwnerUserDialog: false,
      doNotAskAgainDelete: false,
      deletingUser: null as OnStockUser | null,
      addNewCollaboratorDialog: false,
    }
  },
  async mounted() {
    await fetchOnStockUsers()
  },
  methods: {
    async addCollaborators(users: Collaborator[]) {
      this.addNewCollaboratorDialog = false

      if (!this.isAdmin) return
      try {
        this.loading = true

        await Promise.all(
          users.map(async (collaborator) => {
            await createOnStockUser(collaborator.roles, collaborator.email)
          })
        )
      } catch (error) {
        console.error({ error })
      }

      await fetchOnStockUsers()
      this.loading = false
    },
    async deleteUser() {
      if (this.deletingUser && this.isAdmin) {
        this.loading = true
        await deleteOnStockUser(this.deletingUser.id)
        await fetchOnStockUsers()
        this.loading = false
      }

      this.deletingUser = null
    },
    async onClickApproveDeleteUser(doNotAskAgain: boolean) {
      this.doNotAskAgainDelete = doNotAskAgain
      this.deleteUserDialog = false
      await this.deleteUser()
    },
    onClickDeleteCollaborator(user: OnStockUser) {
      this.deletingUser = user

      if (!this.isProjectOwner(user.id)) {
        if (this.doNotAskAgainDelete) {
          this.deleteUser()
        } else {
          this.deleteUserDialog = true
        }
      } else {
        this.deleteProjectOwnerUserDialog = true
      }
    },
    onClickAddNewCollaborator() {
      this.addNewCollaboratorDialog = true
    },
    isProjectOwner(userId: string): boolean {
      let collaborator = this.getCollaborators.find((collaborator) => collaborator.id === userId)
      return collaborator ? collaborator.ownedProjects.length > 0 : false
    },
  },
  computed: {
    getCollaborators(): OnStockUser[] {
      return onStockStore.onStockUsers
    },
    isAdmin(): boolean {
      return !!this.currentUser?.roles?.includes(OnStockPermission.Admin)
    },
    headers(): {
      text: string
      value: string
      sortable: boolean
      align?: string
    }[] {
      return [
        {
          text: '',
          sortable: false,
          align: 'start',
          value: 'avatar',
        },
        {
          text: this.$t('Full name').toString(),
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('Email address').toString(),
          value: 'email',
          sortable: false,
        },
        {
          text: this.$t('Role').toString(),
          value: 'roles',
          sortable: false,
        },
        {
          text: this.$t('Status').toString(),
          value: 'status',
          sortable: false,
        },
        ...(this.isAdmin
          ? [{ text: this.$t('Delete').toString(), value: 'delete', sortable: false }]
          : []),
      ]
    },
    currentUser(): OnStockUser | null {
      return authStore.currentOnStockUser
    },
  },
})
