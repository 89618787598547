var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "account-profile pa-5",
      attrs: { vertical: "", gap: "1rem" },
    },
    [
      _c(
        "cs-flex",
        {
          staticClass: "profile-data",
          attrs: { vertical: "", justify: "flex-start" },
        },
        [
          _c(
            "cs-flex",
            { attrs: { vertical: "" } },
            [
              _c(
                "cs-flex",
                {
                  staticClass: "py-5 px-11",
                  attrs: { "fill-width": "", justify: "stretch" },
                },
                [
                  _c("span", { staticClass: "profile-title" }, [
                    _vm._v(_vm._s(_vm.$t("Account"))),
                  ]),
                  !!_vm.currentUser?.email
                    ? _c("cs-tooltip-avatar", {
                        staticClass: "cs-tooltip-avatar ml-auto",
                        attrs: {
                          email: _vm.currentUser.email,
                          tooltipText: _vm.$t(
                            "This avatar will be visible to others."
                          ),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "div",
                { staticClass: "px-11 py-7" },
                [
                  _c("div", { staticClass: "text-h6 pb-7" }, [
                    _vm._v(_vm._s(_vm.$t("General"))),
                  ]),
                  _c("div", { staticClass: "text-bold text-small mr-auto" }, [
                    _vm._v(_vm._s(_vm.$t("Full name"))),
                  ]),
                  _c("cs-text-field", {
                    attrs: {
                      disabled: "",
                      value: _vm.displayName,
                      variant: "underlined",
                    },
                  }),
                  _c("div", { staticClass: "text-bold text-small mr-auto" }, [
                    _vm._v(_vm._s(_vm.$t("Email"))),
                  ]),
                  _c("cs-text-field", {
                    attrs: {
                      disabled: "",
                      value: _vm.email,
                      variant: "underlined",
                    },
                  }),
                  _c("div", { staticClass: "text-bold text-small mr-auto" }, [
                    _vm._v(_vm._s(_vm.$t("Role"))),
                  ]),
                  _c("cs-text-field", {
                    attrs: {
                      disabled: "",
                      value: _vm.permission,
                      variant: "underlined",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "cs-flex",
            {
              staticClass: "text-bold py-5 px-11",
              attrs: { vertical: "", gap: "1rem" },
            },
            [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      _vm.showLegalDialog = true
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("Privacy policy and terms of condition"))
                  ),
                ]
              ),
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDeleteProfileDialog = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Delete account")))]
              ),
              _c("a", { on: { click: _vm.logout } }, [
                _vm._v(_vm._s(_vm.$t("Log out"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("legal-dialog", {
        model: {
          value: _vm.showLegalDialog,
          callback: function ($$v) {
            _vm.showLegalDialog = $$v
          },
          expression: "showLegalDialog",
        },
      }),
      _c("cs-warning-dialog", {
        attrs: {
          twoButton: "",
          submitText: _vm.$t("Delete"),
          description: _vm.$t(
            "Are you sure you want to delete your account? This action is irreversible."
          ),
          title: _vm.$t("Delete account"),
        },
        on: { submit: _vm.deleteUser },
        model: {
          value: _vm.showDeleteProfileDialog,
          callback: function ($$v) {
            _vm.showDeleteProfileDialog = $$v
          },
          expression: "showDeleteProfileDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }