var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      attrs: {
        vertical: "",
        "fill-height": "",
        "fill-width": "",
        justify: "flex-start",
      },
    },
    [
      _c("explorer-header"),
      _c("v-divider"),
      _c(
        "cs-flex",
        { attrs: { "fill-width": "", "fill-height": "" } },
        [
          _c(
            "cs-navigation-drawer",
            { staticClass: "py-4 px-6", attrs: { width: 300 } },
            [
              _c(
                "cs-btn",
                {
                  staticClass: "justify-start my-2",
                  attrs: {
                    color: "gray",
                    block: "",
                    large: "",
                    outlined: _vm.currentPage === "profile",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.navigate("profile")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Account")) + " ")]
              ),
              _c(
                "cs-btn",
                {
                  staticClass: "justify-start my-2",
                  attrs: {
                    color: "gray",
                    block: "",
                    large: "",
                    outlined: _vm.currentPage === "collaborators",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.navigate("collaborators")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Collaborators")) + " ")]
              ),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "cs-flex",
            {
              attrs: {
                "fill-width": "",
                "fill-height": "",
                align: "flex-start",
              },
            },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }