var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    {
      attrs: {
        value: _vm.value,
        title: _vm.$t("Add collaborator"),
        persistent: "",
      },
      on: {
        input: function ($event) {
          return _vm.$emit("input", false)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "v-col",
                { staticClass: "text-center" },
                [
                  _c(
                    "cs-btn",
                    {
                      attrs: { disabled: !_vm.collaborators.length },
                      on: { click: _vm.handleSendInvitiationClick },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Send invitation")) + " ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "cs-compare-dialog" },
        [
          _c(
            "v-form",
            {
              ref: "addCollaboratorForm",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-row",
                { staticClass: "align-center" },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.emailRules,
                          label: _vm.$t("Email address"),
                          variant: "underlined",
                          required: "",
                        },
                        model: {
                          value: _vm.collaboratorEmail,
                          callback: function ($$v) {
                            _vm.collaboratorEmail = $$v
                          },
                          expression: "collaboratorEmail",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          label: _vm.$t("Role"),
                          variant: "underlined",
                          rules: _vm.permissionRules,
                          items: _vm.permissions,
                          required: "",
                        },
                        model: {
                          value: _vm.permission,
                          callback: function ($$v) {
                            _vm.permission = $$v
                          },
                          expression: "permission",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Admin" },
                        model: {
                          value: _vm.isAdmin,
                          callback: function ($$v) {
                            _vm.isAdmin = $$v
                          },
                          expression: "isAdmin",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", disabled: !!_vm.valid },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g({}, on),
                                    [
                                      _c(
                                        "cs-btn",
                                        {
                                          attrs: {
                                            small: "",
                                            block: "",
                                            disabled: !_vm.valid,
                                          },
                                          on: {
                                            click: _vm.handleAddButtonClick,
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Add")))]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("Fill in the required fields."))
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.collaborators, function (collaborator, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "d-flex align-center" },
                  [
                    _c("div", { staticClass: "flex-grow-1 ma-2" }, [
                      _vm._v(_vm._s(collaborator.email)),
                    ]),
                    _c("div", { staticClass: "ma-2" }, [
                      _vm._v(" " + _vm._s(collaborator.roles.join(", ")) + " "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "ma-2" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleClickRemoveCollaborator(
                                  collaborator
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "grey darken-1" } },
                              [_vm._v(" mdi-close ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }